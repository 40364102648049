import * as React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const SignupPage = () => {
  const data = useStaticQuery(graphql`
    query SignupPageQuery {
      site {
        siteMetadata {
          title
          pageTitles {
            signup
          }
        }
      }
      signup01: file(relativePath: { eq: "signup-hero-01.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1600
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)
  const { title: siteTitle, pageTitles } = data.site.siteMetadata
  const { signup01 } = data

  return (
    <Layout>
      <Seo title={`${siteTitle} | ${pageTitles.signup}`} />
      <BackgroundImage
        Tag="section"
        {...convertToBgImage(getImage(signup01))}
        className="hero signup01"
        role="parallax"
        preserveStackingContext
      >
        <div className="wrapper">
          <h1>{pageTitles.signup}</h1>
        </div>
      </BackgroundImage>
      <section>
        <div className="wrapper">
          <h2>Which class are you interested in?</h2>
          <table>
            <tbody>
              <tr className="odd">
                <td>
                  <strong>Beginners</strong>
                </td>
                <td className="actions">
                  <div>
                    <Link className="info" to="/classes#beginners">
                      Info
                    </Link>
                    <a
                      className="email"
                      href="mailto:joyce@joyoftaichi.com?subject=Beginners%20Class&amp;body=Hello.%20I'm%20interested%20in%20your%20Beginners%20Class."
                    >
                      Sign up
                    </a>
                  </div>
                </td>
              </tr>
              <tr className="even">
                <td>
                  <strong>Energies</strong>
                </td>
                <td className="actions">
                  <div>
                    <Link className="info" to="/classes#energies">
                      Info
                    </Link>
                    <a
                      className="email"
                      href="mailto:joyce@joyoftaichi.com?subject=Energies%20Class&amp;body=Hello.%20I'm%20interested%20in%20your%20Energies%20Class."
                    >
                      Sign up
                    </a>
                  </div>
                </td>
              </tr>
              <tr className="odd">
                <td>
                  <strong>Form 24</strong>
                </td>
                <td className="actions">
                  <div>
                    <Link className="info" to="/classes#form24">
                      Info
                    </Link>
                    <a
                      className="email"
                      href="mailto:joyce@joyoftaichi.com?subject=Form%2024%20Class&amp;body=Hello.%20I'm%20interested%20in%20your%20Form%2024%20Class."
                    >
                      Sign up
                    </a>
                  </div>
                </td>
              </tr>
              <tr className="even">
                <td>
                  <strong>Individual / Private Classes</strong>
                </td>
                <td className="actions">
                  <div>
                    <Link className="info" to="/classes#private">
                      Info
                    </Link>
                    <a
                      className="email"
                      href="mailto:joyce@joyoftaichi.com?subject=Individual%20%2F%20Private%20Class&amp;body=Hello.%20I'm%20interested%20in%20your%20Individual%20%2F%20Private%20Class."
                    >
                      Sign up
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </Layout>
  )
}

export default SignupPage
